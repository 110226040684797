const data = [
  {
    id: 1,
    name: 'Kokou AGBAVON',
    link: 'https://theresilient.dev',
    tags: ['FrontEnd', 'Mobile', 'BackEnd']
  },
  {
    id: 2,
    name: 'Pavel d\'Almeida',
    link: 'https://aitalmeida.tech',
    tags: ['FrontEnd', 'Design']
  },
]

export default data
